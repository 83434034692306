import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import cape from './images/ilustra.png';
import capeRobo from './images/ilustra_robo.png';
import WhatsappButton from './components/Whatssap';

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header toggleSidebar={toggleSidebar} />

      {/* Seção de Introdução */}
      <section className="py-12" style={{ backgroundColor: 'rgb(75, 79, 88)' }}>
        <div className="container mx-auto px-6 flex flex-col lg:flex-row items-center">
          <div className="w-full lg:w-1/2 text-left text-white mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold mb-4">Desbloqueie o máximo potencial dos seus dados!</h2>
            <p className="text-xl mb-4">
              Oferecemos soluções em Engenharia de Dados, Business Intelligence, Ciência de Dados e Desenvolvimento de Software.
            </p>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center">
            <img src={capeRobo} alt="Example" className="w-full h-auto max-h-96" />
          </div>
        </div>
      </section>

      {/* Seção com BG Azul */}
      <section className="py-12 bg-blue-600">
        <div className="container mx-auto flex flex-col lg:flex-row items-center px-6">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
            <img src={cape} alt="Example" className="w-full h-auto max-h-72" />
          </div>
          <div className="w-full lg:w-1/2 text-left text-white">
            <h2 className="text-3xl font-bold mb-4">O que os seus dados têm a dizer?</h2>
            <p className="text-lg mb-4">
              A Análise de Dados é essencial para uma empresa conquistar resultados melhores.
            </p>
          </div>
        </div>
      </section>

      {/* Serviços */}
      <section className="flex-grow py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-semibold text-center mb-12">Nossos Serviços</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <Link to="/engenharia-de-dados" className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Engenharia de Dados</h3>
              <p className="text-gray-700">Desenvolvemos pipelines de dados robustos...</p>
            </Link>

            <Link to="/business-intelligence" className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Business Intelligence</h3>
              <p className="text-gray-700">Transformamos dados brutos em informações valiosas...</p>
            </Link>

            <Link to="/ia-ciencia-dados" className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">IA e Ciência de Dados</h3>
              <p className="text-gray-700">Utilizamos inteligência artificial...</p>
            </Link>

            <Link to="/desenvolvimento-software" className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Desenvolvimento de Software</h3>
              <p className="text-gray-700">Criamos soluções de software personalizadas...</p>
            </Link>
          </div>
        </div>
      </section>

      <WhatsappButton />

      <Footer />
    </div>
  );
};

export default Home;
