import React from 'react';

const WhatsappButton = () => {
  const phoneNumber = '+2399809666';
  const message = 'Olá, gostaria de mais informações!'; // Mensagem inicial opcional
  
  const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

  return (
    <div className="fixed bottom-4 right-4">
      <a
        href={whatsappURL}
        target="_blank"
        rel="noopener noreferrer"
         className="fixed bottom-4 right-4 bg-green-500 p-3 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          className="h-12 w-12 sm:h-16 sm:w-16"
        />
        Fale Conosco
      </a>
    </div>
  );
};

export default WhatsappButton;




