// src/pages/BusinessIntelligence.js

import React from 'react';
import imgCover from '../images/solucoes_consultoria_BI.png'; // Substitua pelo caminho da sua imagem
import img1 from '../images/gerenciamento.png';  // Imagem de exemplo para o bloco
import img2 from '../images/vendas.png';         // Imagem de exemplo para o bloco
import img3 from '../images/satisfacao.png';     // Imagem de exemplo para o bloco
import img4 from '../images/jornada.png';        // Imagem de exemplo para o bloco
import img5 from '../images/custos.png';         // Imagem de exemplo para o bloco
import img6 from '../images/produtividade.png'; 

import imgDashboard from '../images/dashboard.png';  // Imagem de exemplo para Dashboards
import imgAnalysis from '../images/analysis.png';    // Imagem de exemplo para Analysis
import imgReporting from '../images/reporting.png';  // Imagem de exemplo para Reporting

import imgVisualization from '../images/visualization.png';  // Imagem de exemplo para Visualização de dados
import imgAutomatedReports from '../images/automated_reports.png';  // Imagem de exemplo para Distribuição Automatizada de Relatórios
import imgDataCatalog from '../images/data_catalog.png'


import imgGeospatial from '../images/geospatial.png';      // Imagem de exemplo para Análises Geoespaciais
import imgGuidedAnalysis from '../images/guided-analysis.png';  // Imagem de exemplo para Visualizações e Análises Guiadas
import imgMobileDashboards from '../images/mobile-dashboards.png';  // Imagem de exemplo para Dashboards com Acesso Mobile
import imgAutomatedReportss from '../images/automated_reportss.png';  // Imagem de exemplo para Relatórios Automatizados
import imgSelfServiceBI from '../images/self-service-bi.png';      // Imagem de exemplo para Self-Service BI
import imgAlertAutomation from '../images/alert-automation.png';  // Imagem de exemplo para Automação de Alertas
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsappButton from '../components/Whatssap';


const BusinessIntelligence = () => {
    return (
            <div className="min-h-screen bg-blue-900 text-white">
            {/* Header */}
            <Header/>

            <section className="py-16">
            <div className="text-4xl font-bold mb-8 text-center">
                    <h1 className="text-4xl font-bold mb-4">CONSULTORIA DE BUSINESS INTELLIGENCE</h1>

                <div className="flex flex-col lg:flex-row items-center lg:items-start">

                <div className="lg:w-1/2">
                    <p className="text-lg leading-relaxed mb-6">USE TODO O PODER DOS SEUS DADOS E CONQUISTE GRANDES RESULTADOS!</p>
                    <p className="text-lg leading-relaxed mb-6">
                        Business Intelligence (BI), ou Inteligência de Negócios, consiste na análise de dados visando uma tomada 
                        de decisão cada vez mais rápida e efetiva, tornando o planejamento cada vez mais estratégico.
                    </p>
                </div>

                {/* Imagem */}
                <div className="lg:w-1/2 lg:pl-12 mt-8 lg:mt-0">
                    <img src={imgCover} alt="Business Intelligence" className="w-full md:w-96 h-auto rounded-lg shadow-lg" />
                </div>
                </div>
                </div>

            </section>

           {/* Aplique BI ao Seu Negócio Section */}
           <section className="bg-white py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-semibold text-center mb-12">APLIQUE BUSINESS INTELLIGENCE AO SEU NEGÓCIO E MELHORE:</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        
                        {/* Bloco 1 */}
                        <div className="flex flex-col items-center">
                            <img src={img1} alt="Gerenciamento da organização" className="w-32 h-32 object-contain mb-4" />
                            <p className="text-gray-700 text-center">Gerenciamento da organização</p>
                        </div>

                        {/* Bloco 2 */}
                        <div className="flex flex-col items-center">
                            <img src={img2} alt="Volume e lucratividade de vendas" className="w-32 h-32 object-contain mb-4" />
                            <p className="text-gray-700 text-center">Volume e lucratividade de vendas</p>
                        </div>

                        {/* Bloco 3 */}
                        <div className="flex flex-col items-center">
                            <img src={img3} alt="Satisfação do empregado" className="w-32 h-32 object-contain mb-4" />
                            <p className="text-gray-700 text-center">Satisfação do empregado</p>
                        </div>

                        {/* Bloco 4 */}
                        <div className="flex flex-col items-center">
                            <img src={img4} alt="Jornada do consumidor" className="w-32 h-32 object-contain mb-4" />
                            <p className="text-gray-700 text-center">Jornada do consumidor</p>
                        </div>

                        {/* Bloco 5 */}
                        <div className="flex flex-col items-center">
                            <img src={img5} alt="Otimização de custos" className="w-32 h-32 object-contain mb-4" />
                            <p className="text-gray-700 text-center">Otimização de custos</p>
                        </div>

                        {/* Bloco 6 */}
                        <div className="flex flex-col items-center">
                            <img src={img6} alt="Produtividade" className="w-32 h-32 object-contain mb-4" />
                            <p className="text-gray-700 text-center">Produtividade</p>
                        </div>

                    </div>
                </div>
            </section>

            {/* Business Intelligence: Tecnologia Aplicada a Negócios Section */}
            <section className="bg-blue-200 py-16">
                <div className="container mx-auto px-6 text-justify">
                    <h2 className="text-3xl font-bold mb-6  text-gray-700 text-center">BUSINESS INTELLIGENCE: TECNOLOGIA APLICADA A NEGÓCIOS</h2>
                    <p className=" text-gray-700 text-lg mb-4">
                        O time de BI da KDDVision atua de forma que visa ajudar o cliente a conquistar os objetivos da organização, independentemente do setor da economia em que atua ou em qual área da empresa será desenvolvido o projeto.
                    </p>
                    <p className=" text-gray-700 text-lg mb-4">
                        Fazemos entregas de maneira rápida conforme a demanda e empregando a tecnologia e ferramenta necessárias. As soluções encontradas sempre se adequam à realidade da empresa – e não ao contrário.
                    </p>
                    <p className=" text-gray-700 text-lg">
                        Desenvolvemos tanto projetos em escopo fechado, divididos em sprints e com entregas rápidas programadas, quanto em escopo aberto, através de um banco de horas para chamados específicos de suporte à aplicações.
                    </p>
                </div>
            </section>

            {/* Seção MÉTODO D.A.R. */}
            <section className="bg-gray-50 py-16">
                <div className="container mx-auto px-6 text-justify">
                    <h2 className="text-3xl text-gray-700 font-bold mb-6 text-center">MÉTODO D.A.R.</h2>
                    <p className="text-lg text-gray-700 mb-4">
                        Os dados contêm as respostas para uma infinidade de perguntas consideradas essenciais para um negócio conquistar posições superiores. Diferentes níveis hierárquicos e setores de uma empresa possuem preocupações e particularidades específicas. Portanto, dificilmente uma mesma aplicação ou visualização atende a todos.
                    </p>
                    <p className="text-lg  text-gray-700 mb-4">
                        Isso porque o excesso ou escassez de informações pode tanto distrair quanto atrapalhar um usuário – ou, pior, desinformá-lo. Por isso, para garantir que a atenção e as informações corretas sejam extraídas e aplicadas, o time de Business Intelligence da KDDVision utiliza três formas diferentes de exposição de informação:
                    </p>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
                        
                        {/* Dashboards */}
                        <div className="flex flex-col items-center">
                            <img src={imgDashboard} alt="Dashboards" className="w-32 h-32 object-contain mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Dashboards</h3>
                            <p className="text-gray-700 text-center">
                                Versão macro das informações da sua empresa. Um dashboard contém os KPI’s estratégicos para a tomada de decisão.
                            </p>
                        </div>

                        {/* Analysis */}
                        <div className="flex flex-col items-center">
                            <img src={imgAnalysis} alt="Analysis" className="w-32 h-32 object-contain mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Analysis</h3>
                            <p className="text-gray-700 text-center">
                                Painéis com mais filtros, áreas e gráficos. Possui maior interatividade, permitindo uma maior exploração por parte do usuário.
                            </p>
                        </div>

                        {/* Reporting */}
                        <div className="flex flex-col items-center">
                            <img src={imgReporting} alt="Reporting" className="w-32 h-32 object-contain mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Reporting</h3>
                            <p className="text-gray-700 text-center">
                                Um reporting possui uma maior quantidade de informações, contendo dados específicos sobre a organização e tabelas.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

             {/* Seção PROCESSO ANALÍTICO COMPLETO */}
             <section className="bg-blue-100 py-16">
                <div className="container mx-auto px-6 text-justify">
                    <h2 className="text-3xl font-bold mb-6 text-gray-700 text-center">PROCESSO ANALÍTICO COMPLETO</h2>
                    <p className=" text-gray-700 text-lg mb-8">
                        Para que sejam obtidos os melhores insights com base em dados, a análise deve ser feita com eficiência desde o começo.
                    </p>
                    <p className="text-gray-700 text-lg mb-8">
                        Por exemplo: todo o processo de higienização das informações, bem como a conexão de diversas fontes não pode ser feito com descuido. Por conta disso, a KDDVision atua em mais áreas necessárias para a plena análise de dados:
                    </p>

                    {/* Parágrafo 1 - Visualização de dados */}
                    <div className="flex mb-8">
                        <img src={imgVisualization} alt="Visualização de dados" className="w-24 h-24 object-contain mr-4" />
                        <p className="text-gray-700 text-lg leading-relaxed">
                            <strong>Visualização de dados – Qlik Sense e Power BI:</strong> Trata-se da representação de informações por meio de elementos e formatos visuais, como gráficos ou tabelas. Possibilita a descoberta de relações, tendências e padrões nos dados de uma empresa com acurácia e facilidade.
                        </p>
                    </div>

                    {/* Parágrafo 2 - Distribuição Automatizada de Relatórios */}
                    <div className="flex mb-8">
                        <img src={imgAutomatedReports} alt="Distribuição Automatizada de Relatórios" className="w-24 h-24 object-contain mr-4" />
                        <p className="text-gray-700 text-lg leading-relaxed">
                            <strong>Distribuição Automatizada de Relatórios:</strong> É a entrega regular de informações para o usuário sem a necessidade de busca individual e repetida. Pode ser programada ou acionada por um evento específico. Por ser customizável, pode relacionar a análise de dados de diferentes áreas.
                        </p>
                    </div>

                    {/* Parágrafo 3 - Catálogo de Dados */}
                    <div className="flex mb-8">
                        <img src={imgDataCatalog} alt="Catálogo de Dados" className="w-24 h-24 object-contain mr-4" />
                        <p className="text-gray-700 text-lg leading-relaxed">
                            <strong>Catálogo de Dados:</strong> Funciona como um diretório de dados que possibilita ao usuário localizar, navegar por, e combinar dados e informações rapidamente. Facilita a análise individual e independente em cima de tópicos específicos e projetos de negócios.
                        </p>
                    </div>

                </div>
            </section>

             {/* Seção FUNCIONALIDADES DO BUSINESS INTELLIGENCE */}
             <section className="bg-white py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-12 text-gray-700text-center">FUNCIONALIDADES DO BUSINESS INTELLIGENCE</h2>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
                        {/* Análises Geoespaciais */}
                        <div>
                            <img src={imgGeospatial} alt="Análises Geoespaciais" className="mx-auto mb-4 w-20 h-20 object-contain" />
                            <p className="text-lg font-semibold text-gray-700">Análises Geoespaciais</p>
                        </div>

                        {/* Visualizações e Análises Guiadas */}
                        <div>
                            <img src={imgGuidedAnalysis} alt="Visualizações e Análises Guiadas" className="mx-auto mb-4 w-20 h-20 object-contain" />
                            <p className="text-lg font-semibold text-gray-700">Visualizações e Análises Guiadas</p>
                        </div>

                        {/* Dashboards com Acesso Mobile */}
                        <div>
                            <img src={imgMobileDashboards} alt="Dashboards com Acesso Mobile" className="mx-auto mb-4 w-20 h-20 object-contain" />
                            <p className="text-lg font-semibold text-gray-700">Dashboards com Acesso Mobile</p>
                        </div>

                        {/* Relatórios Automatizados */}
                        <div>
                            <img src={imgAutomatedReportss} alt="Relatórios Automatizados" className="mx-auto mb-4 w-20 h-20 object-contain" />
                            <p className="text-lg font-semibold text-gray-700">Relatórios Automatizados</p>
                        </div>

                        {/* Self-Service BI */}
                        <div>
                            <img src={imgSelfServiceBI} alt="Self-Service BI" className="mx-auto mb-4 w-20 h-20 object-contain" />
                            <p className="text-lg font-semibold text-gray-700">Self-Service BI</p>
                        </div>

                        {/* Automação de Alertas */}
                        <div>
                            <img src={imgAlertAutomation} alt="Automação de Alertas" className="mx-auto mb-4 w-20 h-20 object-contain" />
                            <p className="text-lg font-semibold text-gray-700">Automação de Alertas</p>
                        </div>
                    </div>
                </div>
            </section>
            <WhatsappButton/>
            <Footer/>

        </div>
    );
};

export default BusinessIntelligence;
