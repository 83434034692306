// components/Logo.js
import React from 'react';
import logoImage from '../images/transparent.png';  // Importe sua imagem de logo

const Logo = ({ width = '150px', height = 'auto' }) => {
    return (
        <div>
            <img src={logoImage} alt="Logo da Empresa" style={{ width, height }} />
        </div>
    );
}

export default Logo;

