// src/pages/DataEngineering.js

import React from 'react';
import imgCover from '../images/ilustra_capa-eng_dados_v02.png';
import img1 from '../images/Icon_Filtrar-e-transformar-dados.png';
import img2 from '../images/Icon_Desenvolver-ETLs-diversas-.png';
import img3 from '../images/Icon_Potencializar-a-Arquitetura-de-Big-Data.png';
import img4 from '../images/Icon_Construir-Data-Warehouse.png';
import adoptionImage from '../images/img-Adotando-engenharia-de-dados_desktop_v06.png';
import benefitImg1 from '../images/Icon_Filtrar-e-transformar-dados.png';
import benefitImg2 from '../images/Icon_Aplicar-a-Governanca-de-Dados.png';
import benefitImg3 from '../images/Icon_Adequar-a-infraestrutura.png';
import benefitImg4 from '../images/Icon_Tornar-os-dados-acessiveis.png';
import benefitImg5 from '../images/Icon_Permitir-insights.png';
import benefitImg6 from '../images/Icon_Filtrar-dados-confiaveis.png';
import paragraphImg1 from '../images/Icon_Customizar-data-pipelines.png';
import paragraphImg2 from '../images/Icon_Lidar-com-Big-Data-1.png';
import paragraphImg3 from '../images/Icon_Filtrar-dados-confiaveis.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsappButton from '../components/Whatssap';

const DataEngineering = () => {
  return (
    <div className="min-h-screen bg-blue-900 text-white">
      {/* Header with Logo */}
      <Header />
  {/* Seção de Engenharia de Dados */}
  <section className="py-16">
  <div className="container mx-auto px-6">
  <h1 className="text-4xl font-bold mb-8 text-center">ENGENHARIA DE DADOS</h1>
  <div className="flex flex-col lg:flex-row items-center lg:items-start">
  <div className="lg:w-1/2">
      <p className="text-lg mb-6">
        O USO DA TECNOLOGIA PARA ADAPTAR A SUA REALIDADE À RAPIDEZ DO MUNDO DIGITAL
      </p>
      <p className="mb-6 text-lg leading-relaxed">
        A compreensão da abundância de dados e informações gerados por um negócio é fundamental para uma gestão assertiva. A Engenharia de Dados é responsável por captar e processar o grande volume de dados de forma ágil e eficaz, tornando as informações mais acessíveis a toda a organização.
      </p>
      <p className="mb-6 text-lg leading-relaxed">
        Os resultados atingidos pela adoção das práticas de Engenharia de Dados permitem guiar o trabalho para o crescimento do negócio. Assim, criam-se soluções otimizadas e extrai-se todo o poder das informações geradas pela sua empresa.
      </p>
    </div>

    {/* Image */}
    <div className="lg:w-1/2 lg:pl-12 mt-8 lg:mt-0">
      <img src={imgCover} alt="Engenharia de Dados" className="w-full md:w-96 h-auto rounded-lg shadow-lg" />
    </div>
    </div>
    </div>
  </section>


      {/* Solutions Section */}
      <section className="bg-gray-200 py-16">
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-6">
          {/* Block 1 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
            <img src={img1} alt="Filtrar e transformar dados" className="w-24 h-24 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Filtrar e transformar dados</h3>
            <p className="text-gray-700">
              Identificar os dados relevantes para o projeto e torná-los legíveis e utilizáveis para o desenvolvimento de modelos de dados.
            </p>
          </div>

          {/* Block 2 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
            <img src={img2} alt="Desenvolver ETLs diversas" className="w-24 h-24 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Desenvolver ETLs diversas</h3>
            <p className="text-gray-700">
              Integração de fontes e facilitação da transferência e do armazenamento de dados.
            </p>
          </div>

          {/* Block 3 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
            <img src={img3} alt="Potencializar a Arquitetura de Big Data" className="w-24 h-24 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Potencializar a Arquitetura de Big Data</h3>
            <p className="text-gray-700">
              Simplificar as análises e o monitoramento do grande fluxo de dados, lidando com a ingestão, processamento e análise destes.
            </p>
          </div>

          {/* Block 4 */}
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
            <img src={img4} alt="Construir Data Warehouse" className="w-24 h-24 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Construir Data Warehouse</h3>
            <p className="text-gray-700">
              Centralizar dados de diferentes fontes e estruturar um armazém de dados, direcionando todas as informações para o destino desejado.
            </p>
          </div>
        </div>
      </section>

      {/* Adoption Section */}
      <section className="bg-blue-100 py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-semibold text-center text-gray-700 mb-6">COMO ADOTAR A ENGENHARIA DE DADOS EM EMPRESAS</h2>
          <div className="flex justify-center mb-6">
            <img src={adoptionImage} alt="Como Adotar Engenharia de Dados" className="max-w-full h-auto object-contain" />
          </div>
          <p className="text-gray-700 text-lg leading-relaxed text-center">
            A BIX Tecnologia trabalha acompanhando de perto seus clientes para garantir aplicações de Big Data estratégica para cada empresa. Buscamos criar parcerias duradouras, sempre entregando consultorias embasadas nas tecnologias e no modelo do seu negócio, focando nos resultados que você quer atingir. Nosso objetivo é entregar informações valiosas, feitas “sob medida” para sua realidade.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed text-center mt-4">
            Quando adotamos a Engenharia de Dados, é preciso passar por determinadas etapas com precisão e rigor para que, ao finalizar o processo, tenhamos uma solução eficaz e útil a sua gestão e ao alcance de suas metas.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed text-center mt-4">
            A partir destas práticas, é possível atingir um gerenciamento baseado em dados legíveis e úteis. Com a Engenharia de Dados, podemos construir infraestruturas que permitam análises específicas para nossas metas.
          </p>
        </div>
      </section>

      {/* Benefícios da Engenharia de Dados Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-semibold text-center mb-12">BENEFÍCIOS DA ENGENHARIA DE DADOS</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Bloco 1 */}
            <div className="flex flex-col items-center text-center">
              <img src={benefitImg1} alt="Integrar bases de dados" className="w-24 h-24 mb-4" />
              <p className="text-gray-700">Integrar bases de dados e unificar informações</p>
            </div>

            {/* Bloco 2 */}
            <div className="flex flex-col items-center text-center">
              <img src={benefitImg2} alt="Governança de Dados" className="w-24 h-24 mb-4" />
              <p className="text-gray-700">Aplicar a Governança de Dados, garantindo segurança e qualidade</p>
            </div>

            {/* Bloco 3 */}
            <div className="flex flex-col items-center text-center">
              <img src={benefitImg3} alt="Adequar infraestrutura" className="w-24 h-24 mb-4" />
              <p className="text-gray-700">Adequar a infraestrutura para a legibilidade das informações</p>
            </div>

            {/* Bloco 4 */}
            <div className="flex flex-col items-center text-center">
              <img src={benefitImg4} alt="Acessibilidade dos dados" className="w-24 h-24 mb-4" />
              <p className="text-gray-700">Tornar os dados acessíveis para toda a empresa</p>
            </div>

            {/* Bloco 5 */}
            <div className="flex flex-col items-center text-center">
              <img src={benefitImg5} alt="Insights rápidos" className="w-24 h-24 mb-4" />
              <p className="text-gray-700">Descobrir oportunidades e identificar problemas com rapidez</p>
            </div>

            {/* Bloco 6 */}
            <div className="flex flex-col items-center text-center">
              <img src={benefitImg6} alt="Filtrar dados confiáveis" className="w-24 h-24 mb-4" />
              <p className="text-gray-700">Filtrar dados confiáveis e direcionados às metas e resultados</p>
            </div>
          </div>
        </div>
      </section>

       {/* POR QUE SUA EMPRESA PRECISA DE ENGENHARIA DE DADOS? Section */}
       <section className="bg-white py-16"> {/* Fundo branco */}
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-semibold text-center mb-12">POR QUE SUA EMPRESA PRECISA DE ENGENHARIA DE DADOS?</h2>
          
          {/* Parágrafo 1 */}
          <div className="flex mb-8">
            <img src={paragraphImg1} alt="ETL e fluxos de dados" className="w-24 h-24 object-contain mr-4" />
            <p className="text-gray-700 text-lg leading-relaxed">
              As empresas podem ir além do sistema Extrair, Transformar e Carregar (ETL). A partir do armazenamento qualificado de dados, 
              é possível criar fluxos para alcançar resultados nas mais variadas vertentes, desde decisões de marketing até prever quais produtos serão 
              mais vendidos na próxima coleção, por exemplo. Para isso, utilizam-se infraestruturas tecnológicas arquitetadas pela Engenharia de Dados.
            </p>
          </div>
          
          {/* Parágrafo 2 */}
          <div className="flex mb-8">
            <img src={paragraphImg2} alt="Processamento e Big Data" className="w-24 h-24 object-contain mr-4" />
            <p className="text-gray-700 text-lg leading-relaxed">
              O processamento e a manutenção de Big Data necessita de gerenciamento de um engenheiro de dados. Essas práticas permitem coordenar 
              data warehouses ou lakes, montar pipelines expansivos e exclusivos para um objetivo específico da empresa e organizar a imensidão de 
              dados produzida em curtos períodos de tempo.
            </p>
          </div>
          
          {/* Parágrafo 3 */}
          <div className="flex mb-8">
            <img src={paragraphImg3} alt="Qualidade e segurança dos dados" className="w-24 h-24 object-contain mr-4" />
            <p className="text-gray-700 text-lg leading-relaxed">
              Garantir a qualidade, segurança e usabilidade dos dados de uma empresa é parte da função de gerenciamento que a Engenharia de Dados propõe. 
              Criar uma infraestrutura de dados para organizar seu negócio e permitir análises focadas em resultados é fundamental na era digital.
            </p>
          </div>

        </div>
      </section>
      <WhatsappButton/>

      <Footer/>

    </div>
  );
};

export default DataEngineering;
