import React from 'react';
import softwareDevelopmentImage from '../images/software-development.png';  
import app1Image from '../images/icon_auto-processos.png';  
import app2Image from '../images/icon_digit-processos-1.png'; 
import app3Image from '../images/icon_produt-solucoes.png';  
import app4Image from '../images/icon_desenv-solucoes.png';  
import app5Image from '../images/icon_visualiza-inteligente.png';  
import apiImage from '../images/icon_APIS-1.png';  
import scrapingImage from '../images/icon_web-scrap.png';  
import webDevImage from '../images/icon_desenv-web-1.png';  
import mobileDevImage from '../images/icon_desenv-mobile.png';  
import projectCycleImage from '../images/project-cycle.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsappButton from '../components/Whatssap';

const SoftwareDevelopmentPage = () => {
    return (
        <div className="min-h-screen bg-blue-900 ">
           <Header/>

            {/* Seção de Desenvolvimento de Software */}
            <section className="py-16">
                <div className="container mx-auto px-6 text-white" >
                <h1 className="text-4xl font-bold mb-8 text-center">DESENVOLVIMENTO DE SOFTWARE</h1>
                    <div className="flex flex-col lg:flex-row items-center lg:items-start">
                        {/* Textos */}
                        <div className="lg:w-1/2">
                       
                            <p className="mb-6 text-lg leading-relaxed">
                                OBTENHA A SOLUÇÃO COMPLETA PARA O SEU PROJETO!
                                Ter todas as etapas operantes é essencial para o sucesso do seu projeto de dados.
                            </p>
                            <p className="mb-6 text-lg leading-relaxed">
                                Para atingir os melhores resultados e aproveitar todo o potencial das suas análises, garantir a acessibilidade aos dados e as informações geradas é fundamental. É preciso que as aplicações de dados estejam acompanhadas do desenvolvimento de sistemas, possibilitando uma gestão de informações otimizada, com uma visualização compreensível e uma disponibilização do produto funcional e eficaz.
                            </p>
                            <p className="mb-6 text-lg leading-relaxed">
                                Quer trabalhar com dados? A BIX Tecnologia oferece a solução completa para a sua empresa, de forma personalizada, com as características do negócio.
                            </p>
                        </div>

                        {/* Imagem */}
                        <div className="lg:w-1/2 lg:pl-12 mt-8 lg:mt-0">
                            <img src={softwareDevelopmentImage} alt="Desenvolvimento de Software" className="w-full md:w-96 h-auto rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Seção de Aplicações de Desenvolvimento de Software */}
            <section className="bg-blue-100 py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">APLICAÇÕES DE DESENVOLVIMENTO DE SOFTWARE NOS NEGÓCIOS</h2>
                    
                    {/* Bloco de Imagens e Textos */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                        <div className="flex flex-col items-center">
                            <img src={app1Image} alt="Aplicação 1" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center">Automatização de processos</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={app2Image} alt="Aplicação 2" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center">Digitalização de processos manuais</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={app3Image} alt="Aplicação 3" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center">Produtização de soluções</p>
                        </div>
                    </div>
                    
                    {/* Segunda linha com 2 colunas */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col items-center">
                            <img src={app4Image} alt="Aplicação 4" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center">Desenvolvimento de soluções distribuíveis</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={app5Image} alt="Aplicação 5" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center">Visualização inteligente</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Seção de Soluções de Desenvolvimento de Software */}
            <section className="bg-gray-100 py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">SOLUÇÕES DE DESENVOLVIMENTO DE SOFTWARE EM PROJETOS</h2>
                    
                    {/* Bloco de Imagens e Textos */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col items-center">
                            <img src={apiImage} alt="API" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center font-bold">Application Programming Interface (APIs)</p>
                            <p className="text-center">APIs são um conjunto de padrões que compõem uma interface...</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={scrapingImage} alt="Web Scraping" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center font-bold">Web Scraping</p>
                            <p className="text-center">Uma técnica que permite extrair dados para análise de modo automatizado...</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                        <div className="flex flex-col items-center">
                            <img src={webDevImage} alt="Desenvolvimento Web" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center font-bold">Desenvolvimento Web</p>
                            <p className="text-center">Criação de um software conectado a uma rede...</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={mobileDevImage} alt="Desenvolvimento Mobile" className="mb-4 w-32 h-32 object-cover" />
                            <p className="text-center font-bold">Desenvolvimento Mobile</p>
                            <p className="text-center">O desenvolvimento mobile é destaque no mercado por conta da acessibilidade...</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Seção Ciclo de um Projeto */}
            <section className="bg-blue-200 py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">CICLO DE UM PROJETO DE DESENVOLVIMENTO DE SOFTWARE</h2>

                    {/* Imagem Grande */}
                    <div className="flex justify-center">
                        <img src={projectCycleImage} alt="Ciclo de um Projeto de Desenvolvimento de Software" className="w-full object-contain" />
                    </div>
                </div>
            </section>
            <WhatsappButton/>
            <Footer/>
            
        </div>
    );
}

export default SoftwareDevelopmentPage;
