import React from 'react';
import dataScienceImage from '../images/data_science.png';  // Imagem de Ciência de Dados
import aiImage from '../images/ai.png';           // Imagem de Inteligência Artificial
import mlImage from '../images/ml.png';           // Imagem de Machine Learning
import dataAnalysisImage from '../images/data-analysis.png';  // Imagem de Análise de Dados
import statisticsImage from '../images/statistics.png';
import projectCycleImage from '../images/ciclo-1024x435-2.jpg'
import recommendationImage from '../images/recomendation.png';  // Imagem de Recomendação de Itens
import fraudPreventionImage from '../images/fraud-prevention.png';  // Imagem de Prevenção de Fraude
import audienceSegmentationImage from '../images/audience-segmentation.png';  // Imagem de Segmentação de Público
import equipmentMaintenanceImage from '../images/equipment-maintenance.png';  // Imagem de Manutenção de Equipamentos
import strategicPlanningImage from '../images/strategic-planning.png';  // Imagem de Planejamento Estratégico
import churnPreventionImage from '../images/churn-prevention.png';  // Imagem de Prevenção de Churn
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsappButton from '../components/Whatssap';


const DataSciencePage = () => {
    return (
        <div className="min-h-screen bg-blue-900 text-white">

            <Header/>
            
            {/* Seção de Ciência de Dados */}
            <section className="py-16">
                <div className="container mx-auto px-6">
                    <h1 className="text-4xl font-bold mb-8 text-center">CONSULTORIA DE CIÊNCIA DE DADOS</h1>
                    
                    <div className="flex flex-col lg:flex-row items-center lg:items-start">
                        {/* Textos */}
                        <div className="lg:w-1/2">
                            <p className="mb-6 text-lg leading-relaxed">
                                AS MAIS MODERNAS TECNOLOGIAS UNIDAS PARA SE OBTER AS ANÁLISES MAIS COMPLEXAS!
                                Todas as decisões tomadas por uma empresa envolvem investimento tanto financeiro quanto de tempo.
                                A Ciência de Dados é a aliada mais importante para tornar este processo mais certeiro e efetivo na
                                conquista dos resultados que todos desejam.
                            </p>
                            <p className="mb-6 text-lg leading-relaxed">
                                Isso porque a BIX Tecnologia busca sempre conhecer o negócio e as características de cada cliente.
                                Essa postura, unida a um time de profissionais experientes, resulta em projetos de valor inquestionável.
                            </p>
                        </div>

                        {/* Imagem */}
                        <div className="lg:w-1/2 lg:pl-12 mt-8 lg:mt-0">
                            <img src={dataScienceImage} alt="Ciência de Dados" className="w-full md:w-96 h-auto rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Seção Tecnologias Envolvidas */}
            <section className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">TECNOLOGIAS ENVOLVIDAS NA CIÊNCIA DE DADOS</h2>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Inteligência Artificial */}
                        <div className="text-center">
                            <img src={aiImage} alt="Inteligência Artificial" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Inteligência Artificial</h3>
                            <p className="text-lg leading-relaxed">
                                É o uso da computação para a construção de máquinas capazes de executar tarefas que dependiam da inteligência humana.
                                Os progressos dessa tecnologia estão mudando a realidade de todos os setores em que é empregada.
                            </p>
                        </div>

                        {/* Machine Learning */}
                        <div className="text-center">
                            <img src={mlImage} alt="Machine Learning" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Machine Learning</h3>
                            <p className="text-lg leading-relaxed">
                                Como o próprio nome dessa tecnologia sugere, são máquinas (Inteligência Artificial) aprendendo com dados.
                                Os algoritmos criados conseguem identificar padrões e realizar previsões.
                            </p>
                        </div>

                        {/* Análise de Dados */}
                        <div className="text-center">
                            <img src={dataAnalysisImage} alt="Análise de Dados" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Análise de Dados</h3>
                            <p className="text-lg leading-relaxed">
                                Diferentes processos que visam a extração de conhecimento de dados de diversos perfis e origens.
                                Pode ser empregada como a mais importante aliada para a conquista de resultados melhores.
                            </p>
                        </div>

                        {/* Estatística */}
                        <div className="text-center">
                            <img src={statisticsImage} alt="Estatística" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Estatística</h3>
                            <p className="text-lg leading-relaxed">
                                Forma de conhecimento matemático que pode ser empregada junto a tecnologia para a resolução de problemas complexos dentro da sua empresa.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-blue-100 text-gray-800 py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">ANÁLISE PREDITIVA E ANÁLISE PRESCRITIVA</h2>
                    
                    <div className="text-lg leading-relaxed">
                        <p className="mb-6">
                            Durante muito tempo, as pessoas acreditaram que, para se antever eventos que ainda não aconteceram, seriam necessárias “técnicas sobrenaturais”.
                            Hoje, já não há mais mistério. Com a junção de conhecimento matemático e modernas tecnologias, é possível prever o futuro.
                        </p>
                        <p className="mb-6">
                            A Análise Preditiva usa a estatística para prever cenários futuros. Em outras palavras, entender o que vai acontecer com a sua empresa ou instituição.
                            É importante para se confirmar hipóteses ou responder a perguntas complexas que dependem de diversas variáveis.
                        </p>
                        <p>
                            Já a Análise Prescritiva fornece sugestões de ações a serem tomadas e dá conselhos com base em cenários futuros.
                            Ela ajuda a entender o que a sua empresa deve fazer. É possível simular diferentes cenários para se obter os melhores resultados
                            em campanhas, processos ou produtos.
                        </p>
                    </div>
                </div>
            </section>
            {/* Seção Ciclo de um Projeto de Ciência de Dados */}
            <section className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">CICLO DE UM PROJETO DE CIÊNCIA DE DADOS</h2>
                    
                    {/* Imagem Centralizada */}
                    <div className="flex justify-center">
                        <img src={projectCycleImage} alt="Ciclo de um Projeto de Ciência de Dados" className="w-full md:w-3/4 lg:w-1/2 object-contain" />
                    </div>
                </div>
            </section>


            {/* Seção Aplicações de Ciência de Dados */}
            <section className="bg-blue-100 text-gray-800 py-16">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">APLICAÇÕES DE CIÊNCIA DE DADOS EM PROJETOS</h2>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Recomendação de Itens */}
                        <div className="text-center">
                            <img src={recommendationImage} alt="Recomendação de Itens" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Recomendação de Itens</h3>
                        </div>

                        {/* Prevenção de Fraude */}
                        <div className="text-center">
                            <img src={fraudPreventionImage} alt="Prevenção de Fraude" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Prevenção de Fraude</h3>
                        </div>

                        {/* Segmentação de Público */}
                        <div className="text-center">
                            <img src={audienceSegmentationImage} alt="Segmentação de Público" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Segmentação de Público</h3>
                        </div>

                        {/* Manutenção de Equipamentos */}
                        <div className="text-center">
                            <img src={equipmentMaintenanceImage} alt="Manutenção de Equipamentos" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Manutenção de Equipamentos</h3>
                        </div>

                        {/* Planejamento Estratégico e Previsão de Demanda */}
                        <div className="text-center">
                            <img src={strategicPlanningImage} alt="Planejamento Estratégico e Previsão de Demanda" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Planejamento Estratégico e Previsão de Demanda</h3>
                        </div>

                        {/* Prevenção de Churn e Turnover */}
                        <div className="text-center">
                            <img src={churnPreventionImage} alt="Prevenção de Churn e Turnover" className="w-24 h-24 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Prevenção de Churn e Turnover</h3>
                        </div>
                    </div>
                </div>
            </section>
            <WhatsappButton/>
            <Footer/>
        </div>
    );
}

export default DataSciencePage;
