import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo'; // Substitua pelo caminho correto do logo

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header className="bg-gray-800 text-white p-4 relative">
      <div className="container mx-auto flex justify-between items-center">
        {/* Ícone do Logo */}
        <div className="flex items-center">
          <Logo width="40px" height="auto" /> {/* Define o tamanho do logo */}
          
        </div>

        {/* Botão do menu para dispositivos móveis */}
        <button
          className="lg:hidden block text-white focus:outline-none"
          onClick={toggleSidebar}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>

        {/* Links do cabeçalho para telas grandes */}
        <nav className="hidden lg:flex space-x-4">
          <Link to="/engenharia-de-dados" className="hover:text-gray-300">Engenharia de Dados</Link>
          <Link to="/business-intelligence" className="hover:text-gray-300">Business Intelligence</Link>
          <Link to="/ia-ciencia-dados" className="hover:text-gray-300">IA e Ciência de Dados</Link>
          <Link to="/desenvolvimento-software" className="hover:text-gray-300">Desenvolvimento de Software</Link>
        </nav>
      </div>

      {/* Sidebar para dispositivos móveis */}
      {isSidebarOpen && (
        <aside className="fixed inset-0 bg-gray-800 bg-opacity-90 z-50 flex flex-col items-start p-6 lg:hidden">
          <button
            onClick={toggleSidebar}
            className="text-white mb-6"
          >
            Fechar Menu
          </button>
          <nav className="flex flex-col space-y-4 text-white">
            <Link to="/engenharia-de-dados" onClick={toggleSidebar}>Engenharia de Dados</Link>
            <Link to="/business-intelligence" onClick={toggleSidebar}>Business Intelligence</Link>
            <Link to="/ia-ciencia-dados" onClick={toggleSidebar}>IA e Ciência de Dados</Link>
            <Link to="/desenvolvimento-software" onClick={toggleSidebar}>Desenvolvimento de Software</Link>
          </nav>
        </aside>
      )}
    </header>
  );
};

export default Header;
