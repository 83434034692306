// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import DataEngineering from './pages/DataEngineering';
import BusinessIntelligence from './pages/BusinessIntelligence';
import DataScienceAI from './pages/DataScienceAI';
import SoftwareDevelopment from './pages/SoftwareDevelopment';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/engenharia-de-dados" element={<DataEngineering />} />
        <Route path="/business-intelligence" element={<BusinessIntelligence />} />
        <Route path="/ia-ciencia-dados" element={<DataScienceAI />} />
        <Route path="/desenvolvimento-software" element={<SoftwareDevelopment />} />
      </Routes>
    </Router>
  );
}

export default App;
