import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white py-6">
      <div className="container mx-auto flex items-center justify-between px-6">
        <p className="text-sm">© KDD Vision.</p>
        
        {/* Ícones de redes sociais 
        <div className="flex space-x-6 text-lg">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
            <span role="img" aria-label="Facebook">📘</span> Facebook
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
            <span role="img" aria-label="Instagram">📷</span> Instagram
          </a>
        </div>
        */}
      </div>
    </footer>
  );
};

export default Footer;
